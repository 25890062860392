.toggler {
  display: flex;
  justify-content: space-between;
  margin: 30px 20px;
}

.container {
  display: none;
  flex-flow: column;

  @media screen and (max-width: 926px) {
    display: flex;
  }

}

.list {
  list-style: none;
  padding-left: 20px;
  display: flex;
  flex-flow: column;
}

.item {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  //text-transform: uppercase;
  color: #08314F;
  text-decoration: none;
  margin-top: 35px;

  & + & {
    margin-top: 30px;
  }
  a {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #08314F;
    text-decoration: none;
  }
  &__social {
    margin-left: 0px;
    & + & {
      margin-left: 30px;
    }
  }
}

.opened {
  background: url("/menu-bg.png") center center no-repeat #FFFFFF;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
  overflow-y: hidden;
}

.title {
  margin: 15px 0 10px;
  font-weight: bold;
  font-size: 18px;
}
