.logo {
  display: flex;
  align-items: center;
}
.link {
  &_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #fff;

    @media screen and (max-device-width: 1280px){
      grid-gap: 0;
    }

    @media screen and (max-device-width: 768px){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 24px;
    }
  }

  &_type {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #08314F;
    display: block;
    margin-bottom: 20px;
  }

  &_list_wrapper {
    padding: 0;
    list-style-type: none;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    li {
      margin-bottom: 20px;
    }

    a {
      color: #08314F;
    }
  }
}

@media screen and (max-device-width: 768px){
  .mobile_hide {
    display: none;
  }
}

