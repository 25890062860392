.qr {

  &_codes_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 170px;
    grid-gap: 40px;
  }

  &_codes_section_mobile {
    display: none;
  }

  &_container {
    background: #FFFFFF;
    box-shadow: 0 6px 20px rgba(13, 6, 84, 0.1);
    border-radius: 15px;
    padding: 30px;
    position: relative;
    cursor: pointer;
  }

  &_text {
    margin-left: 5px;
    //margin-top: 5px;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: #7C8999;
  }

  &_text_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  &_business_logo {
    position: absolute;
    z-index: 1;
    top: 27%;
    left: 31%;
    height: 100px;
    opacity: 1;
    transition: 0.2s;

    &_hide {
      opacity: 0;
    }
  }

  &_blur {
    filter: blur(8px);
    transition: 0.1s;

    &_hide {
      filter: blur(0);
    }
  }
}

@media screen and (max-width: 1650px) {
  .qr_codes_section {
    margin-right: 5%;
  }
  .qr_code images {
    max-width: 100% ;
  }
  .qr-code-text {
    font-size: 19px;
    line-height: 19px;
  }
}

@media screen and (max-width: 768px) {
  .qr_business_logo {
    top: 25%;
    left: 33%;
  }

  .qr_codes_section {
    display: none;

    &_mobile {
      display: block;
      width: 100%;
      margin-top: 20px;
    }
  }

  .qr_container {
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto 25px;
    padding-left: 30px;
  }
}
