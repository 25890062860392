$column-size: 95px;
$row-size: 123px;
$row-gap: 9px;
$column-gap: 10px;

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 485px;
  width: 224px;
  filter: drop-shadow(0px 9.15425px 18.3085px rgba(109, 109, 109, 0.25));

  @media screen and (max-device-width: 768px) {
    height: 335px;
    width: 154px;
  }
}

.store {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 10.7286px;
  line-height: 13px;
  color: #000000;

  @media screen and (max-device-width: 768px) {
    margin: 0 0 2px 1px;
    font-size: 7px;
    line-height: 9px;
  }
}

.banner {

  &_image {
    width: 726px;
    height: 641px;
    z-index: 0;
    position: relative;

    @media screen and (max-width: 1307px) {
      width: 639px;
    }

    @media screen and (max-width: 1097px) {
      width: 737px;
    }


    @media screen and (max-width: 926px) {
      width: 615px;
      margin-top: 30px;
      height: 429px;
    }

    @media screen and (max-device-width: 768px) {
      height: 268px;
      width: 375px;
      margin-top: 30px;
    }
  }
}

.card_image {
  height: 83px;
  width: 100%;
  background-size: contain;
  position: relative;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media screen and (max-device-width: 768px) {
    height: 47px;
  }
}

.topbar_logo {
  height: 30px;
  width: 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5.96032px rgba(13, 6, 84, 0.1);
  border-radius: 2.98016px;

  > img {
    height: 30px;
    width: 30px;
  }
}

.topbar_container {
  display: flex;
  align-items: center;
}

.topbar_business {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 16px;

  @media screen and (max-width: 768px) {
    margin: 0 10px;
  }
}

.topbar_business_name {
  font-weight: 700;
  font-size: 10.7286px;
  line-height: 13px;
  color: #000000;
  background-color: transparent;
  margin-bottom: 2px;

  @media screen and (max-width: 768px) {
    font-size: 7px;
  }
}

.topbar_business_member {
  font-weight: 400;
  font-size: 8px;
  line-height: 11px;
}

.topbar_menu {
  font-weight: 400;
  font-size: 8px;
  line-height: 11px;
  color: #09043B;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;

  > li {
    opacity: 0.33;
  }

  @media screen and (max-width: 768px) {
    margin-top: 7px;
    font-size: 5px;
  }
}

li.topbar_menu_active {
  color: #200ED3;
  opacity: 1;
}

.reward__card__points {
  font-size: 5px;
  line-height: 5px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #938FBA;
  border: 0.596032px solid #938FBA;
  box-sizing: border-box;
  border-radius: 1.19206px;
  height: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    height: 9px;
    font-size: 4px;
  }
}

.reward__card__info {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.reward__card__exist {
  font-size: 5px;
  line-height: 5px;
  display: flex;
  align-items: center;
  color: #000000;
  margin: 6px 8px 0 0;

  @media screen and (max-width: 768px) {
    font-size: 4px;
    margin: 1px 3px 0 0;
  }
}

.reward_item_container {
  display: flex;
  flex-direction: column;
  background: #F2F4FA;
  width: 259px;
  height: 485px;
  position: absolute;
  overflow: hidden;

  @media screen and (max-width: 1307px) {
    width: 229px;
  }

  @media screen and (max-width: 768px) {
    width: 147px;
  }
}

.reward_first {
  box-shadow: 0 10px 20px rgba(109, 109, 109, 0.25);
  transform: rotate(-10deg);
  top: 85px;

  @media screen and (max-width: 768px) {
    top: 55px;
  }
}

.reward_second {
  box-shadow: 0 10px 20px rgba(109, 109, 109, 0.25);
  left: 242px;

  @media screen and (max-width: 1307px) {
    left: 210px;
  }

  @media screen and (max-width: 1120px) {
    left: 194px;
  }

  @media screen and (max-width: 768px) {
    left: 115px;
  }
}

.reward_third {
  box-shadow: 0 10px 20px rgba(109, 109, 109, 0.25);
  transform: rotate(10deg);
  right: 0;
  top: 85px;

  @media screen and (max-width: 768px) {
    top: 55px;
  }
}

.reward_topbar {
  //height: 99px;
  background: #FFFFFF;
  padding: 0 12px 14px;

  @media screen and (max-width: 768px) {
    padding: 0 12px 7px;
  }
}

.reward_topbar_phone {
  display: block;
  margin: 9px 3px;
  width: 100%;
}

.reward_navbar {
  display: flex;
  padding: 5px 0 11px;
  background: #FFFFFF;
  justify-content: space-around;
  position: relative;

  &:before {
    content: '';
    border-top: 1px solid #200ED3;
    position: absolute;
    height: 1px;
    width: 44px;
    left: 54px;
    top: 0;
  }

}

.reward_navbar_item {
  display: flex;
  flex-direction: column;

  > img {
    height: 14px;
    margin-bottom: 3px;
  }

  > span {
    font-weight: 400;
    font-size: 7.74679px;
    line-height: 9px;
    text-align: center;
    color: #000000;
  }
}

.reward_list {
  flex: 1;
  padding: 10px;

  @media screen and (max-width: 768px) {
    padding: 8px 10px;
  }
}

.rewards_item {
  height: 140px;
  width: auto;
  background: #FFFFFF;
  box-shadow: 0 3px 11px rgba(13, 6, 84, 0.1);
  border-radius: 3px;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 80px;
  }
}

.rewards_item_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 9px;
}

.reward__card__name {
  margin: 2px 6px 3px;
  font-weight: 700;
  font-size: 5px;
  line-height: 7px;
  color: #000000;

  @media screen and (max-width: 768px) {
    margin: 0 3px 1px;
  }
}

.reward__card__date {
  font-style: italic;
  font-weight: 400;
  font-size: 5px;
  line-height: 7px;
  color: #000000;
  margin: 4px 0 0 6px;

  @media screen and (max-width: 768px) {
    font-size: 4px;
    margin: 1px 0 0 3px;
  }
}

.reward__business__logo {
  width: 15px;
  height: 15px;
  background-size: contain;
  bottom: 62px;
  position: absolute;
  right: 5px;
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
  background: #FFF;

  @media screen and (max-width: 768px) {
    bottom: 36px;
  }
}

