$breakpoint-tablet: 768px;
$breakpoint-laptop: 998px;

.card {
    background: #F8FAFE;
    box-shadow: 0 6px 20px rgba(13, 6, 84, 0.1);
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 40px 30px;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
        margin: 20px;
        box-sizing: border-box;
        padding: 20px;
        //width: 100%;
    }

    h5 {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #08314F;


        @media (max-width: $breakpoint-tablet) {
            font-size: 20px;
            line-height: 24px;
        }

        @media (max-width: $breakpoint-laptop) {
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
        }
    }

    p {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #7C8999;

        @media (max-width: $breakpoint-laptop) {
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
        }

        @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0;
        }
    }

    img {
        margin: 35px 0;
        width: 100%;
        max-height: 180px;
        @media (max-width: $breakpoint-tablet) {
            height: 107px;
            margin: 35px 0;
        }
    }
}


